import {Link} from 'react-router-dom'
import {ReactComponent as Logo} from '../../assets/logo.svg'

const navigation = [
  { name: 'Le Domaine', href: '/#domaine' },
  { name: 'Photos', href: '/photos' },
  { name: 'Contactez-nous', href: '/contact' },
]

export default function Header() {
  return (
    <header className="bg-teal-600">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full py-6 flex items-center justify-between border-b border-teal-500 lg:border-none">
          <div className="flex flex-row items-center">
            <Link to={'/'}>
              <div className="flex flex-col justify-start font-serif text-left font-bold text-white">
                <Logo  className="h-12 w-24"/>
              </div>
            </Link>
            <div className="hidden ml-10 space-x-8 lg:flex lg:flex-row ">
              {navigation.map((link) => (
                  <Link  key={link.name} to={link.href} className="text-base font-medium text-white hover:text-teal-50">
                    <p>{link.name}</p>
                  </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
          {navigation.map((link) => (
            <Link  key={link.name} to={link.href} className="text-base font-medium text-white hover:text-teal-50">
              <p>{link.name}</p>
            </Link>
          ))}
        </div>
      </nav>
    </header>
  )
}
