import {Link} from 'react-router-dom'
import {PhoneIcon} from '@heroicons/react/outline'

const navigation = {
  main: [
    { name: 'Gallerie photos', href: '/photos' },
    { name: 'Mentions légales', href: '/legal' },
    { name: 'Contact', href: '/contact' },
  ],
  social: [
  ],
}

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <Link to={item.href} className="text-base text-gray-500 hover:text-gray-900">
                {item.name}
              </Link>
            </div>
          ))}
        </nav>
        <div className="w-full text-center mt-4 lg:mt-6">
          <div className="mx-auto flex flex-row justify-center items-center">
            <PhoneIcon className="h-6 w-6 mr-4"/>
            <p className="underline font-bold hover:text-gray-900">+33(0)6 07 03 75 17</p>
          </div>
        </div>
        <p className="mt-8 text-center text-base text-gray-400">Domaine du breuchet, Neung-sur-Beuvron, Loir-et-Cher.</p>
      </div>
    </footer>
  )
}
