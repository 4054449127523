import Home from './routes/home/Home'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Gallery from "./routes/gallery/Gallery"
import Legal from "./routes/legal/Legal"
import Contact from "./routes/contact/Contact"
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="photos" element={<Gallery />} />
        <Route path="legal" element={<Legal />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
