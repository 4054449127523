import {ReactComponent as Home} from '../../assets/home.svg'
import {ReactComponent as Target} from '../../assets/target.svg'
import {ReactComponent as Deer} from '../../assets/deer.svg'
import {ReactComponent as Dish} from '../../assets/dish.svg'
import {ReactComponent as Wine} from '../../assets/wine.svg'

const features = [
  { name: "L'accueil", icon: Home, content: "Accueil au sein du domaine avec une équipe dévouée pour veiller à la réussite de votre journée. Petit déjeuner convivial et rappel des consignes de sécurité." },
  { name: '3 battues', icon: Target, content: 'Matinée avec 3 battues.' },
  { name: 'Le déjeuner', icon: Dish, content: 'Partage d’un déjeuner au Pavillon de chasse avec la dégustation de produits régionaux.' },
  { name: '3 battues', icon: Target, content: 'Après-midi avec 3 battues.' },
  { name: 'Le tableau', icon: Deer, content: 'Partage du tableau avec une bourriche de gibier pour chaque participant.' },
  { name: "Verre de l'amitié", icon: Wine, content: '' },
]

export default function Process() {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32" id="day">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl font-serif">
          Notre chasse à la journée
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8 lg:h-64 ">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-teal-200 rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 lg:mt-2 tracking-tight">{feature.name}</h3>
                    <p className="mt-5 text-base text-gray-500">
                      {feature.content}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
