import JotformEmbed from 'react-jotform-embed';
import Header from '../shared/Header'
import Footer from '../home/Footer'
import {PhoneIcon} from '@heroicons/react/outline'

export default function Contact() {
  
  return (
    <div className="bg-white">
      <Header/>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-teal-600 tracking-wide uppercase my-4 sm:my-6">Contactez-nous</h2>
          <div className="mx-auto flex flex-row justify-center items-center  my-4 sm:my-6">
            <PhoneIcon className="h-6 w-6 mr-4"/>
            <p className="underline font-bold hover:text-gray-900">+33(0)6 07 03 75 17</p>
          </div>
          <p className="my-4 sm:my-6">OU</p>
          <JotformEmbed  src="https://form.jotformeu.com/220061709215042"  />
        </div>
      </div>
      <Footer />
    </div>
  )
}
