import Header from '../shared/Header'
import Photos from './Photos'
import Footer from '../home/Footer'

export default function Gallery() {
  return (
    <div className="bg-white">
      <Header />
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-teal-600 tracking-wide uppercase">Gallerie Photos</h2>
          <Photos />
        </div>
      </div>
      <Footer />
    </div>
  )
}
