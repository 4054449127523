export default function Host() {
  return (
    <div className="bg-white px-6 lg:px-8">
      <div className="mx-auto max-w-2xl text-center bg-green-200 py-2 rounded">
        <p className="text-lg sm:text-2xl text-green-700 font-semibold">
          Possibilité de location de gîte sur place.
        </p>
      </div>
    </div>
  )
}
