import {Fragment} from 'react'
import Hero from './Hero'
import Process from './Process'
import Host from './Host'
import Gallery from './Gallery'
import Pricing from './Pricing'
import Footer from './Footer'
import Cta from './Cta'
export default function Home() {
  return (
    <Fragment>
      <Hero />
      <Process />
      <Host />
      <Gallery />
      <Pricing />
      <Cta />
      <Footer />
    </Fragment>
  );
}
