import photo_1 from '../../assets/photo_1.jpeg';
import photo_2 from '../../assets/photo_2.jpeg';
import photo_3 from '../../assets/photo_3.png';
import photo_4 from '../../assets/dog_truck.jpeg';
const people = [
  {
    name: 'chien Gator',
    imageUrl: photo_4,
  },
  {
    name: 'Maison',
    imageUrl: photo_1,
  },
  {
    name: 'Etang',
    imageUrl: photo_2,
  },
  {
    name: 'Deux Chiens',
    imageUrl: photo_3,
  },
  // More people...
]

export default function Gallery() {
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <ul
            role="list"
            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-2 lg:gap-x-4"
          >
            {people.map((person) => (
              <li key={person.name}>
                <div className="space-y-4">
                  <div className="aspect-w-3 aspect-h-2">
                    <img className="object-cover shadow-lg rounded-lg" src={person.imageUrl} alt="" />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
