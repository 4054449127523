import img1 from '../../assets/photos/img1.jpeg';
import img2 from '../../assets/photos/img2.jpeg';
import img3 from '../../assets/photos/img3.jpeg';
import img4 from '../../assets/photos/img4.jpeg';
import img5 from '../../assets/photos/img5.jpeg';
import img6 from '../../assets/photos/img6.jpeg';
import img7 from '../../assets/photos/img7.jpeg';
import img8 from '../../assets/photos/img8.jpeg';
import img9 from '../../assets/photos/img9.jpeg';
import img10 from '../../assets/photos/img10.jpeg';
const images = [
  {
    name: 'plaine1',
    imageUrl: img1,
  },
  {
    name: 'etang1',
    imageUrl: img2,
  },
  {
    name: 'chemin1',
    imageUrl: img3,
  },
  {
    name: 'plaine2',
    imageUrl: img4,
  },
  {
    name: 'plaine3',
    imageUrl: img5,
  },
  {
    name: 'chemin2',
    imageUrl: img6,
  },
  {
    name: 'etang2',
    imageUrl: img7,
  },
  {
    name: 'maison',
    imageUrl: img8,
  },
  {
    name: 'plaine3',
    imageUrl: img9,
  },
  {
    name: 'plante',
    imageUrl: img10,
  },
  // More images...
]

export default function Photos() {
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <ul
            role="list"
            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-2 lg:gap-x-8"
          >
            {images.map((photo) => (
              <li key={photo.name}>
                <div className="space-y-4">
                  <div className="aspect-w-3 aspect-h-2">
                    <img className="object-cover shadow-lg rounded-lg" src={photo.imageUrl} alt={photo.name}/>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
