/* This example requires Tailwind CSS v2.0+ */
import hunter from '../../assets/hunter.jpeg';

export default function Cta() {
  return (
    <div className="relative bg-gray-800">
      <div className="h-56 bg-teal-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img
          className="w-full h-full object-cover"
          src={hunter}
          alt=""
        />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-32">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">Venez fouler la terre de Sologne !</h2>
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl font-serif">Profitez d’une nature unique sur ce territoire préservé.</p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                Contactez-nous
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
